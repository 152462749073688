const areObjectsDifferent = (firstObject, secondObject) => {
  if (!firstObject && !secondObject) return false;
  if (!firstObject || !secondObject) return true;

  const prevKeys = Object.keys(firstObject);
  const currentKeys = Object.keys(secondObject);

  if (prevKeys.length !== currentKeys.length) return true;

  return prevKeys.some((key) => firstObject[key] !== secondObject[key]);
};

export default areObjectsDifferent;