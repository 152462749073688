import React, { createRef } from "react";
import Cards from "react-credit-cards";
import PropTypes from "prop-types";
import { Desktop } from "../../../utils/media-queries";
import CreditCardInput from "@workwave_it/react-credit-card-input";

import SecureFields from "./../components/payment/pci-proxy";
import { CircularProgress } from "@material-ui/core";
import { currencies } from "../../currencies/currencies";
import { displayTotalAmount } from "../../../utils/utils";
import { priceCalculations } from "@workwave_it/lawnbot-pricing-calculations";

export default class PaymentForm extends React.Component {
  state = {
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    cardValid: false,
    cardReady: false,
    errorMessage: "",
  };
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.checkValid = this.checkValid.bind(this);

    this.paymentProcessor = "cardVault";
    this.secureForm = createRef();

    if (this.props.registerCharge) {
      this.props.registerCharge(this.chargePciProxy);
    }
  }

  componentDidMount() {
    this.checkValid();
  }

  componentDidUpdate(prevProps) {
    // Check if priceBlocks or availableServices have changed
    const prevSelectedItems = [
      ...prevProps.priceBlocks.filter((t) => t.selected),
      ...prevProps.availableServices.filter((t) => t.selected),
    ];

    const currentSelectedItems = [
      ...this.props.priceBlocks.filter((t) => t.selected),
      ...this.props.availableServices.filter((t) => t.selected),
    ];

    // If the number of selected items has changed, recheck validity
    if (prevSelectedItems.length !== currentSelectedItems.length) {
      this.checkValid();
    } else if (
      // Or if the term has changed
      prevProps.term !== this.props.term ||
      // Or if the totals have changed
      prevProps.firstTotal !== this.props.firstTotal ||
      prevProps.allTotal !== this.props.allTotal
    ) {
      this.checkValid();
    }
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.id.replace("card-", "") });
  };

  handlePCIProxyInputFocus = (e) => {
    let val = "number";
    if (e.field === "cardNumber") {
      val = "number";
    } else {
      val = "cvv";
    }
    this.setState({ focus: val });
    return false;
  };

  handlePCIProxyInputChange = (e) => {
    const { togglePayButton } = this.props;
    let valid = true;
    for (const i in e) {
      if (!e[i].valid) {
        valid = false;
      }
    }

    const btnState = valid ? "enable" : "disabled";
    togglePayButton(btnState);
    this.setState({ cardReady: valid });
  };

  validateExpiryDate = (expiryString) => {
    const expiry = expiryString.split(" ").join("");
    if (!expiry) {
      return { valid: false, message: "Expiry date is not available" };
    }
    const [month, year] = expiry.split("/");
    const currentYear = new Date().getFullYear().toString().slice(-2);
    const currentMonth = new Date().getMonth() + 1;

    if (!month || !year) {
      return { valid: false, message: "Expiry date is not available" };
    }

    if (
      expiry &&
      month &&
      year &&
      (Number.parseInt(year) < Number.parseInt(currentYear) ||
        (Number.parseInt(year) === Number.parseInt(currentYear) &&
          Number.parseInt(month) < currentMonth))
    ) {
      return { valid: false, message: "Your card is expired" };
    }
    return { valid: true, message: "Expiry is valid" };
  };

  handleInputChange = (e) => {
    const { id, value } = e.target;

    const name = id.replace("card-", "").replace(/\s*/g, "").replace(/\//g, "");

    this.setState({ [name]: value });

    this.checkValid();
  };

  handleBlur = (e) => {
    this.setState({ focus: "number" });
    this.checkValid();
  };

  chargePciProxy = (e) => {
    this.secureForm.current.secureFields.submit();
  };

  submitCard = () => {
    if (
      (this.state.cardReady &&
        this.props.allTotal > 0 &&
        !this.props.bypassPayment) ||
      (this.props.allTotal > 0 && this.props.bypassPayment)
    ) {
      if (!this.props.charging) {
        const { number, expiry, cvc } = this.state;
        const { submitPayment } = this.props;
        const stripped = number.replace(/\s/g, "");
        const payload = { number: stripped, cvc, exp: expiry };

        submitPayment("card", payload);
      } else {
        console.log("don't charge");
      }
    } else {
      /// don't charge
    }
  };

  submitTransaction = (data) => {
    const { submitPayment } = this.props;

    const { expiry } = data;

    const validExpiry = this.validateExpiryDate(expiry);
    if (!validExpiry.valid) {
      this.setState({ errorMessage: validExpiry.message });
      return;
    }

    if (
      (this.props.allTotal > 0 && !this.props.bypassPayment) ||
      (this.props.allTotal > 0 && this.props.bypassPayment)
    ) {
      if (!this.props.charging) {
        submitPayment("lawnbot-credit-card-vault", data);
      } else {
        console.log("no charging");
      }
    } else {
      console.log("no money");
    }
  };

  isValid = (type, isValid) => {
    this.setState({ cardValid: isValid });
    this.checkValid();
  };

  checkValid = () => {
    const { number, cvc, expiry } = this.state;
    const { saveCardInfo, togglePayButton } = this.props;
    const stripped = number.replace(/\s/g, "");

    let exp = expiry;
    if (exp.length >= 3) {
      exp = exp.replace(/^([0-9]{2})\s*\/*\s*([0-9]*)$/g, "$1 / $2");
    }

    saveCardInfo({ number: stripped, cvc, exp });

    const amount =
      this.props.term === "application"
        ? this.props.firstTotal
        : this.props.allTotal;
    if (
      this.state.cardValid &&
      this.state.cvc.length >= 3 &&
      this.state.expiry.length >= 4
    ) {
      /// enable button
      if (amount > 0) {
        togglePayButton("enable");
        this.setState({ cardReady: true });
        return true;
      }
      togglePayButton("disabled");
      this.setState({ cardReady: false });
      return true;
    }
    /// disable button
    togglePayButton("disabled");
    this.setState({ cardReady: false });
    return false;
  };

  clearErrorMessages = () => {
    this.setState({ errorMessage: "" });
  };

  getPricing = () => {
    return this.props.term === "application"
      ? (
          this.props.firstTotal +
          this.props.firstTotal * (this.props.serviceTax / 100)
        ).toFixed(2)
      : (
          this.props.allTotal +
          this.props.allTotal * (this.props.serviceTax / 100)
        ).toFixed(2);
  };

  render() {
    let currentCurrency = "$";
    const currency = currencies?.find(
      (currency) => currency.name === this.props.currency,
    );
    if (currency) {
      currentCurrency = currency.symbol;
    }

    const selectedItems = [
      ...this.props.priceBlocks.filter((t) => t.selected),
      ...this.props.availableServices.filter((t) => t.selected),
    ];

    const summary = priceCalculations.getSummaryPricing(
      selectedItems,
      this.props.term,
      {
        autoPayPriceMethod: this.props.autoPayPriceMethod,
        autoPayMonthlyPricePeriod: this.props.autoPayMonthlyPricePeriod || 12,
        serviceTax: this.props.serviceTax,
        lookupZipCodeTax: this.props.lookupZipCodeTax,
        taxOverride: this.props.taxOverride,
        currency: this.props.currency,
      },
    );
    const { number, expiry, cvc, focus, name } = this.state;

    return !this.props.bypassPayment ? (
      <div
        id="PaymentForm"
        onBlur={this.handleBlur}
        style={{ minWidth: 400, textAlign: "center" }}
      >
        {this.props.cardProcessor === "stripe" ? (
          <Cards
            cvc={cvc}
            expiry={expiry.replace("/", "")}
            focused={focus}
            name={name}
            number={number}
            issuer={"visa"}
            callback={this.isValid}
          />
        ) : (
          <img
            src={"/assets/images/creditcard.png"}
            style={{ width: "95%" }}
            alt=""
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "space-around",
            fontFamily: "Open Sans",
            fontSize: 17,
            maxWidth: "80%",
            margin: "auto",
            flexWrap: "wrap",
          }}
        >
          {this.props.cardProcessor === "stripe" ? (
            <CreditCardInput
              cardNumberInputProps={{
                value: this.state.number,
                onChange: this.handleInputChange,
                onFocus: this.handleInputFocus,
              }}
              cardExpiryInputProps={{
                value: this.state.expiry,
                onChange: this.handleInputChange,
                onFocus: this.handleInputFocus,
              }}
              cardCVCInputProps={{
                value: this.state.cvc,
                onChange: this.handleInputChange,
                onFocus: this.handleInputFocus,
              }}
              fieldClassName="input"
            />
          ) : null}

          {this.props.cardProcessor === "lawnbot-credit-card-vault" ? (
            <SecureFields
              production={this.props.isLive}
              ref={this.secureForm}
              config={{
                merchantID: this.props.isLive ? "3000016939" : "1100024951",
                fields: {
                  cardNumber: {
                    placeholderElementId: "pci-card-number",
                    inputType: "tel",
                  },
                  cvv: {
                    placeholderElementId: "pci-cvv-number",
                    inputType: "tel",
                  },
                },
                options: {},
              }}
              focusChange={this.handlePCIProxyInputFocus}
              paymentMade={this.submitTransaction}
              cardInfo={this.handlePCIProxyInputChange}
              clearErrorMessages={this.clearErrorMessages}
              validateExpiryDate={this.validateExpiryDate}
            />
          ) : null}

          {this.props.cardProcessor === "real-green-payment-processing" &&
          this.props.crmId ? (
            <iframe
              title={"rgpp"}
              id="payment-iframe"
              width="100%"
              height="670px"
              seamless="seamless"
              frameBorder="0"
              allowtransparency="true"
              src={this.props.paymentToken}
            />
          ) : null}
        </div>

        <div
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            fontSize: 13,
            marginTop: 15,
            color: "rgb(169 150 150)",
            position: "relative",
            marginBottom: 20,
          }}
        >
          Your card information will be encrypted to securely process payment.
        </div>

        <div
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            fontSize: 13,
            marginTop: 15,
            color: "red",
            position: "relative",
            marginBottom: 20,
            height: 10,
          }}
        >
          {this.state.errorMessage ? this.state.errorMessage : null}
        </div>

        <Desktop>
          {this.props.cardProcessor === "real-green-payment-processing" &&
          this.props.crmId ? null : (
            <div
              style={{
                paddingLeft: 35,
                paddingRight: 35,
                width: "79%",
                marginTop: 20,
              }}
            >
              {/* biome-ignore lint/a11y/useKeyWithClickEvents: not required at the moment */}
              <div
                style={{
                  width: "100%",
                  borderRadius: 25,
                  backgroundColor:
                    this.state.cardReady &&
                    this.props.allTotal > 0 &&
                    !this.props.charging
                      ? "#1565C0"
                      : "#ccc",
                  height: 60,
                  color: "white",
                  fontSize: 26,
                  fontWeight: "bolder",
                  paddingTop: 13,
                  paddingBottom: 13,
                  textAlign: "center",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={
                  this.props.cardProcessor === "lawnbot-credit-card-vault"
                    ? this.chargePciProxy
                    : this.submitCard
                }
              >
                {this.props.charging ? <CircularProgress /> : null}
                {this.props.cardProcessor === "lawnbot-credit-card-vault" &&
                this.props.term === "application" ? (
                  <span
                    style={{
                      top: this.props.charging ? -13 : 0,
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    Sign Up
                  </span>
                ) : null}
                {this.props.cardProcessor === "lawnbot-credit-card-vault" &&
                this.props.term !== "application" ? (
                  <span
                    style={{
                      top: this.props.charging ? -13 : 0,
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    Sign Up
                  </span>
                ) : null}
                {this.props.cardProcessor === "stripe" ? (
                  <>
                    {" "}
                    {displayTotalAmount(
                      selectedItems,
                      this.props.term,
                      {
                        autoPayPriceMethod: this.props.autoPayPriceMethod,
                        autoPayMonthlyPricePeriod:
                          this.props.autoPayMonthlyPricePeriod,
                        serviceTax: this.props.serviceTax,
                        lookupZipCodeTax: this.props.lookupZipCodeTax,
                        taxOverride: this.props.taxOverride,
                      },
                      currentCurrency,
                    )}
                  </>
                ) : null}
              </div>
            </div>
          )}
        </Desktop>
      </div>
    ) : (
      <Desktop>
        <div id="PaymentForm" style={{ width: 250 }}>
          <div
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              width: "79%",
              marginTop: 20,
            }}
          >
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: not required at the moment */}
            <div
              style={{
                width: "100%",
                borderRadius: 25,
                backgroundColor: summary.price > 0 ? "#1565C0" : "#ccc",
                height: 60,
                color: "white",
                fontSize: 26,
                fontWeight: "bolder",
                paddingTop: 13,
                paddingBottom: 13,
                textAlign: "center",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={this.submitCard}
            >
              <span style={{ width: "100%" }}>⚡ BUY NOW</span>
            </div>
          </div>
        </div>
      </Desktop>
    );
  }
}

PaymentForm.propTypes = {
  submitPayment: PropTypes.func,
  togglePayButton: PropTypes.func,
  saveCardInfo: PropTypes.func,
};
