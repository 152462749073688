import React from "react";
import { CalculatingQuoteComponent } from "../components/calculating.component";

export default function gettingStarted(self) {
  return [
    {
      id: "get-calculating-step",
      component: (
        <CalculatingQuoteComponent
          onboarding={self.onboarding}
          preview={self.preview}
          setPriceBlocks={self.setPriceBlocks}
          apiURL={self.dashboardApi}
          selectedServices={self.state.selectedServices}
          answers={self.answers}
          ga={self.ga}
          companyId={self.companyId}
          botId={self.botId}
          bot={self._bot.current}
          totalLotSize={self.state.totalLotSize}
          adjustedLotSize={self.state.adjustedLotSize}
          chatId={self.state.chatId}
          customerZip={`${self.state.customerZip}`}
        />
      ),
      asMessage: false,
      waitAction: true,
      dispatchMessage: () => {
        self._modal.current.handleOpen();
      },
    },
    { id: "thanks", message: "Thank you so much for buying", end: true },
  ];
}
