import { programTypesConfigContext } from "../../contexts/programTypes/programTypesConfig.context";

const getServiceEntryId = (name) => {
  const programType = programTypesConfigContext.config.find((entry) => {
    return entry.name === name;
  });
  return programType.entryPoint;
};

export default getServiceEntryId;
