import DashboardApi from "./api/dashboardApi";
import { priceCalculations } from "@workwave_it/lawnbot-pricing-calculations";

export const getAvailableServices = async (params) => {
  const {
    serviceTypes,
    companyId,
    botId,
    quoteId,
    estimate,
    answers,
    postalCode,
    onboarding,
    preview,
    autoPayPriceMethod,
    autoPayMonthlyPricePeriod,
    serviceTax,
    lookupZipCodeTax,
  } = params;

  try {
    const response = await DashboardApi.post(
      `/v1/rtp/quote/services${
        onboarding ? "?onboarding=true" : preview ? "?preview=true" : ""
      }`,
      {
        serviceTypes,
        companyId,
        quoteId,
        botId,
        estimate,
        answers,
        postalCode,
      },
    );

    // Sort services by alwaysOnTop flag
    const ontop = response.data.filter((i) => i.alwaysOnTop);
    const notOnTop = response.data.filter((i) => !i.alwaysOnTop);
    const allServices = [...ontop, ...notOnTop];

    // Filter out selected services and add pricing
    const filteredServices = allServices
      .filter((service) => !serviceTypes.includes(service.serviceType))
      .map((service) => {
        // Calculate application net price for each service
        const applicationNetPrice = priceCalculations.getDisplayPricing(
          service,
          "application",
          autoPayPriceMethod,
          autoPayMonthlyPricePeriod,
          serviceTax,
          lookupZipCodeTax,
        );

        // Return service with appFirstCost added
        return {
          ...service,
          appFirstCost: applicationNetPrice,
          selected: false,
        };
      });

    return filteredServices;
  } catch (error) {
    console.log("Error fetching available services:", error);
    return [];
  }
};
