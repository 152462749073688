import { proxy } from "valtio";
import getProgramTypesConfig from "../../services/programTypes/getProgramTypesConfig";

export const programTypesConfigContext = proxy({ config: [] });

export const setProgramTypesConfigContext = async () => {
  try {
    const response = await getProgramTypesConfig();
    programTypesConfigContext.config = response;
  } catch (error) {
    console.log("There was an error fetching program types", error);
    programTypesConfigContext.config = [];
  }
};
