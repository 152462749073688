import DashboardApi from "../../utils/api/dashboardApi";

const getProgramTypesConfig = async () => {
  try {
    const response = await DashboardApi.get("/v2/program-types");
    const programTypes = await response.data;
    return programTypes;
  } catch (error) {
    console.log("There was an error fetching program types", error);
    return [];
  }
};

export default getProgramTypesConfig;
